/* eslint-disable */
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query, 
  where, 
  getDocs,
  getDoc
} from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState, useEffect } from "react"
import { auth, db, storage  } from "../firebase"
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { async } from "@firebase/util";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    function signup(email, password) {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      return signInWithPopup(auth, provider)
 //     return createUserWithEmailAndPassword(auth, email, password)
      }
      function login(email, password) {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
      //  return signInWithEmailAndPassword(auth, email, password)
        return signInWithPopup(auth, provider)
      }  
      function logout() {
        const auth = getAuth();
        return signOut(auth)
      }

      function resetPassword(email) {
        const auth = getAuth();
        return sendPasswordResetEmail(auth, email)
      }

      function updateEmail(email) {
        return currentUser.updateEmail(email)
      }
    
      function updatePassword(password) {
        return currentUser.updatePassword(password)
      }
    
    
      useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
          setCurrentUser(user)
          //console.log(currentUser);
          setLoading(false)
        })
        return unsubscribe
      }, [])  

      // When current user changes need to verify the user is there or not
      useEffect(()=>{
        if (currentUser){
          async function checkUser(){
            try {
              if(currentUser.email && currentUser.email !== ""){    
                const docRef = doc(db, 'Customers', currentUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                  navigate("/dashboard");
                }
                else {
      //            setError("Account does not exits in BMT please use sign up link");
                  // console.log("Account does not exits in CustomRapid please use sign up link")
                  // alert("Account does not exits in CustomRapid please use sign up link")

                  //If user is not present in customRapid create it
                  await setDoc(docRef, {
                    name: currentUser.displayName,
                    email: currentUser.email,
                    mobileNumber:currentUser.phoneNumber,
                    photoURL: currentUser.photoURL,
                    timeStamp: serverTimestamp(),
                    encryptionKey: "",
                    exchanges: [],
                    strategies: [],
                    algos: [],
                    tradeEngines:[]
                  });
                  navigate("/dashboard");
                }  
              }else{
      //          setError("Failed to log in");
                alert("Contact Support")
              }
            } catch(error){
              console.log(error)
              alert("Contact Support")
      //        setError("Failed to log in");
            }
          }   
          checkUser()
        }
       },[currentUser])



    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword,
        updateEmail,
        updatePassword
      }


  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

