import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc,
  getFirestore
} from "firebase/firestore";

import { getDatabase, ref, set, onValue, update } from "firebase/database";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import { auth, db, rdb, storage } from "../firebase";
import { useAuth } from "../context/AuthContext";

import React, { useEffect, useState, useRef } from 'react';

import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
  Grid, Container, Typography, Button, Collapse, Paper, Stack, TextField, Box,
  Switch,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  IconButton,
  Pagination
} from '@mui/material';
import { Business, ExpandMore as ExpandMoreIcon, Delete, ChevronRight, ArrowBackIosNew, ArrowBackIos } from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { blue, green, pink, red } from '@mui/material/colors';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  TotalOrderLineChartCard
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------
const itemsPerPage = 4;

const useStyles = {
  root: {
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
    marginTop: 2,
  },
  listItem: {
    border: '1px solid #ccc',
    marginBottom: 1,
  },
  header: {
    backgroundColor: '#1976D2',
    color: '#fff',
    padding: 1,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: 1,
  },
  footer: {
    borderTop: '1px solid #ccc',
    padding: 1,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 1,
  },
};
const exitOptions = [
  {
    value: 'Point',
    label: 'Point',
  },
  {
    value: 'Price',
    label: 'Price',
  },
];

let selectedOrder = {}

export default function TradesPage() {
  const theme = useTheme();
  const [showTriggerInfo, setShowTriggerInfo] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedOrderLogs, setSelectedOrderLogs] = useState([]);
  const [enableMidSell, setEnableMidSell] = useState(false);
  const [enableTL, setEnableTL] = useState(false);
  const [pageRu, setPageRu] = useState(1);
  const [pageCo, setPageCo] = useState(1);
  const [pageRj, setPageRj] = useState(1);
  const [orders, setOrders] = useState([]);
  const [runningOrders, setRunningOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [rejectedOrders, setRejectedOrders] = useState([]);

  const [trade, setTrade] = useState({});
  const [exchange, setExchange] = useState({});
  const [strategy, setStrategy] = useState({});

  const [edit, setEdit] = useState(false);
  const [stoploss, setStoploss] = useState(0);
  const [takeprofit, setTakeprofit] = useState(0);
  const [takeprofit1, setTakeprofit1] = useState(0);
  const [exitOption, setExitOption] = useState("")
  const [objectPage, setObjectPage] = useState(false)

  const [loading, setLoading] = useState(false);
  const { signup, currentUser } = useAuth();

  // Page settings start
  const handlePageRuChange = (event, value) => {
    setPageRu(value);
  };
  const handlePageCoChange = (event, value) => {
    setPageCo(value);
  };
  const handlePageRjChange = (event, value) => {
    setPageRj(value);
  };

  const showObjectPage = () => {
    setObjectPage(false)
  };

  const startIndexRu = (pageRu - 1) * itemsPerPage;
  const endIndexRu = startIndexRu + itemsPerPage;
  const currentItemsRunning = runningOrders.slice(startIndexRu, endIndexRu);

  const startIndexCo = (pageCo - 1) * itemsPerPage;
  const endIndexCo = startIndexCo + itemsPerPage;
  const currentItemsCompleted = completedOrders.slice(startIndexCo, endIndexCo);

  const startIndexRj = (pageRj - 1) * itemsPerPage;
  const endIndexRj = startIndexRj + itemsPerPage;
  const currentItemsRejected = rejectedOrders.slice(startIndexRj, endIndexRj);
  // end

  const onRefreshOrder = () => {

  };

  const onShowTriggerInfo = () => {
    setShowTriggerInfo(true)
  };

  const filterItemsByStatus = (status, finsihed, items) => items.filter(item =>
    (item.exchanges[0].orderstatus.toUpperCase() === status.toUpperCase())
    && (item.finished.toUpperCase() === status.toUpperCase())
  );

  const filterItemsByStatusRejected = (status, items) => items.filter(item =>
    (item.exchanges[0].orderstatus.toUpperCase() === status.toUpperCase())
  );

  const handleShowLogs = (orderId) => {
    // alert("Sorry I am not ready yet :-( For now you cannot edit live orders")
    // return
    const logs = orders.find((order) => order.id === orderId);
    setSelectedOrderId(orderId);
    setSelectedOrderLogs(logs ? [logs] : []);
    selectedOrder = logs
    // setEnableMidSell(logs && logs.data &&
    //   logs.data.setting &&
    //   logs.data.setting.enableIntermediateSelling.toUpperCase() === "YES" ?
    //   true : false)

    if (logs.data.orderType === "CE") {

      setEnableTL(logs && logs.data
        && logs.data.setting.refStatCE &&
        logs.data.setting.refStatCE.enableTrailingLoss.toUpperCase() === "YES" ?
        true : false)

    }
    if (logs.data.orderType === "PE") {

      setEnableTL(logs && logs.data
        && logs.data.setting.refStatPE &&
        logs.data.setting.refStatPE.enableTrailingLoss.toUpperCase() === "YES" ?
        true : false)

    }

    setObjectPage(true)
  };

  const handleEdit = () => {
    setStoploss(selectedOrderLogs[0].data.stopLoss)
    setTakeprofit(selectedOrderLogs[0].data.takeProfit)
    setTakeprofit1(selectedOrderLogs[0].data.takeProfit1)
    setExitOption(selectedOrderLogs[0].data.isExitBasedPoint ? "Point" : "Price")
    setEdit(true)
  };

  const handleSave = async () => {
    let order = selectedOrderLogs[0]
    try {
      // const orderDocRef = doc(db, 'ordersNifty50Options', order.doc_id);
      // const ordersCollection = ref(rdb, 'ordersNifty50Options');
      // const orderDocRef = ref(rdb)
      // await updateDoc(orderDocRef, {
      //   stopLoss: parseFloat(stoploss),
      //   takeProfit: parseFloat(takeprofit)
      // });
      const updates = {};
      let sl = 0
      let tk = 0
      let tk1 = 0
      if (exitOption === "Point" && selectedOrderLogs[0].data.orderType === "CE") {
        sl = selectedOrderLogs[0].data.ltp - parseFloat(stoploss)
        tk = selectedOrderLogs[0].data.ltp + parseFloat(takeprofit)
        tk1 = selectedOrderLogs[0].data.ltp + parseFloat(takeprofit1)
        // setStoploss(sl)
        // setTakeprofit(tk)
      } else if (exitOption === "Point" && selectedOrderLogs[0].data.orderType === "PE") {
        sl = selectedOrderLogs[0].data.ltp + parseFloat(stoploss)
        tk = selectedOrderLogs[0].data.ltp - parseFloat(takeprofit)
        tk1 = selectedOrderLogs[0].data.ltp - parseFloat(takeprofit1)
      }
      else {
        sl = parseFloat(stoploss)
        tk = parseFloat(takeprofit)
        tk1 = parseFloat(takeprofit1)
        // setStoploss(sl)
        // setTakeprofit(tk)
      }

      // updates['/ordersNifty50Options/' + order.doc_id + '/' + 'stopLoss'] = parseFloat(stoploss)
      // updates['/ordersNifty50Options/' + order.doc_id + '/' + 'takeProfit'] = parseFloat(takeprofit)
      updates['/ordersNifty50Options/' + order.doc_id + '/' + 'stopLoss'] = parseFloat(sl)
      updates['/ordersNifty50Options/' + order.doc_id + '/' + 'takeProfit'] = parseFloat(tk)
      updates['/ordersNifty50Options/' + order.doc_id + '/' + 'takeProfit1'] = parseFloat(tk1)
      updates['/ordersNifty50Options/' + order.doc_id + '/' + 'tl1'] = 0
      updates['/ordersNifty50Options/' + order.doc_id + '/' + 'isExitBasedPoint'] = exitOption === "Point" ? true : false
      updates['/ordersNifty50Options/' + order.doc_id + '/' + 'isExitBasedPrice'] = exitOption === "Price" ? true : false
      // updates['/ordersNifty50Options/' + order.doc_id + '/' + 'setting/enableIntermediateSelling'] = enableMidSell ? "Yes" : "No"
      // updates['/ordersNifty50Options/' + order.doc_id + '/' + 'refStat/enableTrailingLoss'] = enableTL ? "Yes" : "No"
      update(ref(rdb), updates).then(() => {
        console.log('Order updated successfully!');
        setStoploss(sl)
        setTakeprofit(tk)
        setTakeprofit1(tk1)
        order.data.stopLoss = parseFloat(sl)
        order.data.takeProfit = parseFloat(tk)
        order.data.takeProfit1 = parseFloat(tk1)
        order.data.isExitBasedPoint = exitOption === "Point" ? true : false
        order.data.isExitBasedPrice = exitOption === "Price" ? true : false
        // order.data.setting.enableIntermediateSelling = enableMidSell ? "Yes" : "No"
        // order.data.refStat.enableTrailingLoss = enableTL ? "Yes" : "No"
        setSelectedOrderLogs(order ? [order] : []);
        setEdit(false)
        alert("Order updated")
      })
        .catch((error) => {
          alert("Error in updating")
          setEdit(false)
        });
    } catch (error) {
      console.error('Error updating order: ', error);
    }
  };

  const handleEditOrder = (orderId) => {
    alert("Edit not enabled")
  };

  const handleForceExit = async () => {
    let order = selectedOrderLogs[0]
    try {
      const updates = {};
      updates['/ordersNifty50Options/' + order.doc_id + '/' + 'takeProfit'] = 0
      update(ref(rdb), updates).then(() => {
        console.log('Force exit executed successfully!.Please wait for 10 secs and refresh.');
        order.data.takeProfit = 0
        setSelectedOrderLogs(order ? [order] : []);
        alert("Force exit executed successfully!.Please wait for 10 secs and refresh.")
      })
        .catch((error) => {
          alert("Error in exiting. Contact support")
        });
    } catch (error) {
      console.error('Error updating order: ', error);
    }

  };

  const handleChangeMidSell = (event) => {
    setEnableMidSell(event.target.checked);
  };

  const handleChangeTL = (event) => {
    setEnableTL(event.target.checked);
  };


  useEffect(() => {
    // Fetch data from the 'orders' collection
    const fetchData = async () => {
      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const aStrategies = docSnap.data().tradeEngines.map(obj => obj.id);

        // const ordersCollection = collection(db, 'ordersNifty50Options');
        // const squery = query(ordersCollection, where('strategyId', 'in', aStrategies));
        // const ordersSnapshot = await getDocs(squery);
        const ordersCollection = ref(rdb, 'ordersNifty50Options');

        onValue(ordersCollection, (snapshot) => {
          const _orders = snapshot.val();
          if (_orders) {
            const filteredOrders = Object.values(_orders).filter(order =>
              aStrategies.includes(order.strategyId)
            );
            const ordersData = [];
            let orderProcessed = 0;
            for (const order of filteredOrders) {
              // const orderDetails = orders[orderId];
              orderProcessed++;
              //Live refresh for selected order
              // if (selectedOrderLogs[0] && selectedOrderLogs[0].referenceOrderId === order.referenceOrderId){
              //   setSelectedOrderLogs([order])
              // }

              if (selectedOrder && selectedOrder.data && selectedOrder.data.referenceOrderId === order.referenceOrderId){
                selectedOrder.data = order
                setSelectedOrderLogs([selectedOrder])
              }
              // Assuming each order document has a 'description' field
              // const { description } = doc.data();
              const orderData = order;
              const details = `${orderData.exchanges[0].orderno} - ${orderData.exchanges[0].orderstatus} - Master`;
              const orderExchange = `${orderData.exchanges[0].exchangeName}`;
              const orderColor = `${orderData.exchanges[0].orderstatus === "rejected" ? "error" : "info"}`
              const pl = `${orderData.pl}`;
              ordersData.push({
                id: orderProcessed, details: details, type: orderExchange,
                color: orderColor, data: orderData,
                pl: pl,
                doc_id: orderData.referenceOrderId
              });

            }
            setOrders(ordersData);

            const runningItems = ordersData.filter(item =>
              (item.data.exchanges[0].orderstatus.toUpperCase() === "executed".toUpperCase() ||
                item.data.exchanges[0].orderstatus.toUpperCase() === "complete".toUpperCase()
                || item.data.exchanges[0].orderstatus.toUpperCase() === "") &&
              item.data.finished.toUpperCase() === "No".toUpperCase()
            );
            const rejectedItems = ordersData.filter(item =>
              item.data.exchanges[0].orderstatus.toUpperCase() === "rejected".toUpperCase()
            );
            const completedItems = ordersData.filter(item =>
              (item.data.exchanges[0].orderstatus.toUpperCase() === "executed".toUpperCase() ||
                item.data.exchanges[0].orderstatus.toUpperCase() === "complete".toUpperCase() ||
                item.data.exchanges[0].orderstatus.toUpperCase() === "")
              && item.data.finished.toUpperCase() === "Yes".toUpperCase()
            );
            setRunningOrders(runningItems)
            setCompletedOrders(completedItems)
            setRejectedOrders(rejectedItems)

            let oTradeData = {};
            oTradeData.type = "trade"
            oTradeData.additionalText = " Trades"
            oTradeData.total = ordersData.length
            oTradeData.path = "/dashboard/addexchange"
            setTrade(oTradeData)

            let oExchangeData = {};
            oExchangeData.type = "exchange"
            oExchangeData.additionalText = " Exchanges"
            oExchangeData.total = docSnap.data().exchanges ? docSnap.data().exchanges.length : 0
            oExchangeData.path = "/dashboard/exchanges"
            setExchange(oExchangeData)

            let oStrategyData = {};
            oStrategyData.type = "strategy"
            oStrategyData.additionalText = " Strategies"
            oStrategyData.total = docSnap.data().strategies ? docSnap.data().strategies.length : 0
            oStrategyData.path = "/dashboard/mystrategies"
            setStrategy(oStrategyData)
          } else {
            setOrders([]);
            let oTradeData = {};
            oTradeData.type = "trade"
            oTradeData.additionalText = " Trades"
            oTradeData.total = ordersData.length
            oTradeData.path = "/dashboard/addexchange"
            setTrade(oTradeData)

            let oExchangeData = {};
            oExchangeData.type = "exchange"
            oExchangeData.additionalText = " Exchanges"
            oExchangeData.total = docSnap.data().exchanges ? docSnap.data().exchanges.length : 0
            oExchangeData.path = "/dashboard/exchanges"
            setExchange(oExchangeData)

            let oStrategyData = {};
            oStrategyData.type = "strategy"
            oStrategyData.additionalText = " Strategies"
            oStrategyData.total = docSnap.data().strategies ? docSnap.data().strategies.length : 0
            oStrategyData.path = "/dashboard/mystrategies"
            setStrategy(oStrategyData)
          }
        });

        // ordersSnapshot.forEach((doc) => {
        //   orderProcessed++;
        //   // Assuming each order document has a 'description' field
        //   // const { description } = doc.data();
        //   const orderData = doc.data();
        //   const details = `${orderData.exchanges[0].orderno} - ${orderData.exchanges[0].orderstatus} - Master`;
        //   const orderExchange = `${orderData.exchanges[0].exchangeName}`;
        //   const orderColor = `${orderData.exchanges[0].orderstatus === "rejected" ? "error" : "info"}`
        //   const pl = `${orderData.pl}`;
        //   ordersData.push({
        //     id: orderProcessed, details: details, type: orderExchange,
        //     color: orderColor, data: orderData,
        //     pl: pl,
        //     doc_id: doc.id
        //   });
        // });
        // setOrders(ordersData);
        // let oTradeData = {};
        // oTradeData.type = "trade"
        // oTradeData.additionalText = " Trades"
        // oTradeData.total = ordersData.length
        // oTradeData.path = "/dashboard/addexchange"
        // setTrade(oTradeData)

        // let oExchangeData = {};
        // oExchangeData.type = "exchange"
        // oExchangeData.additionalText = " Exchanges"
        // oExchangeData.total = docSnap.data().exchanges ? docSnap.data().exchanges.length : 0
        // oExchangeData.path = "/dashboard/exchanges"
        // setExchange(oExchangeData)

        // let oStrategyData = {};
        // oStrategyData.type = "strategy"
        // oStrategyData.additionalText = " Strategies"
        // oStrategyData.total = docSnap.data().strategies ? docSnap.data().strategies.length : 0
        // oStrategyData.path = "/dashboard/mystrategies"
        // setStrategy(oStrategyData)
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> My Trades | customrapid </title>
      </Helmet>

      <Container maxWidth="xl">
        <Box
          sx={{
            height: '50px',
            backgroundColor: 'rgb(30, 136, 229)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns the text to the left
            paddingLeft: '16px', // Adds some padding to the left for spacing
            borderRadius: '8px', // Optional: curved edges
            color: 'white', // Text color
            mb: 5
          }}
        >
          <Typography variant="h6">
            My Trades &gt; NIFTY 50 Options
          </Typography>
        </Box>
        {!objectPage &&
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            {/* <Typography variant="h4" gutterBottom>
            My Trades &gt; NIFTY 50 Options
          </Typography> */}

            <Button variant="contained" component={Link} to="/dashboard/app" startIcon={<Iconify icon="eva:arrow-left-fill" />}>
              Back
            </Button>
          </Stack>}
        {objectPage &&
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Button variant="contained" onClick={() => showObjectPage()} startIcon={<Iconify icon="eva:arrow-left-fill" />}>
              Back
            </Button>
          </Stack>}
        <Grid container spacing={2} alignItems="center" justifyContent="left">

          {/* First Column: Order List */}
          {!objectPage && <Grid item xs={12} sm={12} md={4}>
            <Paper variant="outlined" style={{ height: '550px' }} square>
              <Stack direction="row" alignItems="center" justifyContent="center" m={2}>
                <Typography variant="h5">
                  Running
                </Typography>
              </Stack>
              {currentItemsRunning.length > 0 ? (
                <List>
                  {currentItemsRunning.map((order) => (
                    <ListItem key={order.data.exchanges[0].orderno} disablePadding>
                      {/* The button that wraps the content */}
                      <ListItemButton onClick={() => handleShowLogs(order.id)}>
                        {/* Avatar for the Order */}
                        <ListItemAvatar>
                          <Avatar alt="Order" sx={{ bgcolor: blue[500] }}>
                            <RunCircleIcon />
                          </Avatar>
                        </ListItemAvatar>

                        {/* Primary and Secondary Text for the Order */}
                        <ListItemText
                          style={{ paddingRight: '2px' }}
                          primary={
                            <span style={{
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 2,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              lineHeight: '1.4em',
                              maxHeight: '2.8em',
                              wordBreak: 'break-word',
                              fontWeight: 500,
                              fontSize: '0.8rem'
                            }}>
                              {`Order: ${order.data.setting.enablePaperTrade ? "Paper" : "Live"} - ${order.data.setting.tradeEngineName} - id ${order.data.setting.enablePaperTrade ? "000" : order.data.exchanges[0].orderno}`}
                            </span>
                          }
                          secondary={
                            <span style={{
                              display: 'block',
                              fontSize: '0.655rem',
                              color: 'gray'
                            }}>
                              {`P&L: ${order.data.pl.toFixed(2)} | Date: ${order.data.exchanges[0].date ? new Date(order.data.exchanges[0].date).toLocaleString() : "Not found"}`}
                            </span>
                          }

                        />
                      </ListItemButton>

                      {/* Secondary Action - Chevron Icon */}
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="navigate" onClick={() => handleShowLogs(order.id)}>
                          <ChevronRight />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>

                  ))}
                </List>
              ) : (
                <Typography variant="body1" align="center" color="textSecondary">
                  No data to display
                </Typography>
              )}
              <Box m={2}>
                <Pagination
                  count={Math.ceil(runningOrders.length / itemsPerPage)}
                  page={pageRu}
                  onChange={handlePageRuChange}
                  color="primary"
                  style={{ marginTop: '20px' }}
                />
              </Box>
            </Paper>
          </Grid>}

          {!objectPage && <Grid item xs={12} sm={12} md={4}>
            <Paper variant="outlined" style={{ height: '550px' }} square>
              <Stack direction="row" alignItems="center" justifyContent="center" m={2}>
                <Typography variant="h5">
                  Closed
                </Typography>
              </Stack>
              {currentItemsCompleted.length > 0 ? (
                <List>
                  {currentItemsCompleted.map((order) => (
                    <ListItem key={order.data.exchanges[0].orderno} disablePadding>
                      <ListItemButton onClick={() => handleShowLogs(order.id)}>
                        <ListItemAvatar>
                          <Avatar alt="Order" sx={{ bgcolor: green[500] }}>
                            <CheckCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        {/* Primary and Secondary Text for the Order */}
                        <ListItemText
                          style={{ paddingRight: '2px' }}
                          primary={
                            <span style={{
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 2,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              lineHeight: '1.4em',
                              maxHeight: '2.8em',
                              wordBreak: 'break-word',
                              fontWeight: 500,
                              fontSize: '0.8rem'
                            }}>
                              {`Order: ${order.data.setting.enablePaperTrade ? "Paper" : "Live"} - ${order.data.setting.tradeEngineName} - id ${order.data.setting.enablePaperTrade ? "000" : order.data.exchanges[0].orderno}`}
                            </span>
                          }
                          secondary={
                            <span style={{
                              display: 'block',
                              fontSize: '0.655rem',
                              color: 'gray'
                            }}>
                              {`P&L: ${order.data.pl.toFixed(2)} | Date: ${order.data.exchanges[0].date ? new Date(order.data.exchanges[0].date).toLocaleString() : "Not found"}`}
                            </span>
                          }

                        />
                      </ListItemButton>

                      {/* Secondary Action - Chevron Icon */}
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="navigate" onClick={() => handleShowLogs(order.id)}>
                          <ChevronRight />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body1" align="center" color="textSecondary">
                  No data to display
                </Typography>
              )}
              <Box m={2}>
                <Pagination
                  count={Math.ceil(completedOrders.length / itemsPerPage)}
                  page={pageCo}
                  onChange={handlePageCoChange}
                  color="primary"
                  style={{ marginTop: '20px' }}
                />
              </Box>
            </Paper>
          </Grid>}

          {!objectPage && <Grid item xs={12} sm={12} md={4}>
            <Paper variant="outlined" style={{ height: '550px' }} square>
              <Stack direction="row" alignItems="center" justifyContent="center" m={2}>
                <Typography variant="h5">
                  Rejected
                </Typography>
              </Stack>
              {currentItemsRejected.length > 0 ? (
                <List>
                  {currentItemsRejected.map((order) => (
                    <ListItem key={order.data.exchanges[0].orderno} disablePadding>
                      <ListItemButton onClick={() => handleShowLogs(order.id)}>
                        <ListItemAvatar>
                          <Avatar alt="Order" sx={{ bgcolor: red[500] }}>
                            <CancelIcon />
                          </Avatar>
                        </ListItemAvatar>
                        {/* Primary and Secondary Text for the Order */}
                        <ListItemText
                          style={{ paddingRight: '2px' }}
                          primary={
                            <span style={{
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 2,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              lineHeight: '1.4em',
                              maxHeight: '2.8em',
                              wordBreak: 'break-word',
                              fontWeight: 500,
                              fontSize: '0.8rem'
                            }}>
                              {`Order: ${order.data.setting.enablePaperTrade ? "Paper" : "Live"} - ${order.data.setting.tradeEngineName} - id ${order.data.setting.enablePaperTrade ? "000" : order.data.exchanges[0].orderno}`}
                            </span>
                          }
                          secondary={
                            <span style={{
                              display: 'block',
                              fontSize: '0.655rem',
                              color: 'gray'
                            }}>
                              {`P&L: ${order.data.pl.toFixed(2)} | Date: ${order.data.exchanges[0].date ? new Date(order.data.exchanges[0].date).toLocaleString() : "Not found"}`}
                            </span>
                          }

                        />
                      </ListItemButton>

                      {/* Secondary Action - Chevron Icon */}
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="navigate" onClick={() => handleShowLogs(order.id)}>
                          <ChevronRight />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body1" align="center" color="textSecondary">
                  No data to display
                </Typography>
              )}
              <Box m={2}>
                <Pagination
                  count={Math.ceil(rejectedOrders.length / itemsPerPage)}
                  page={pageRj}
                  onChange={handlePageRjChange}
                  color="primary"
                  style={{ marginTop: '20px' }}
                />
              </Box>
            </Paper>
          </Grid>}

          {/* Fourth Column: Order Logs */}
          {objectPage && <Grid item xs={12} sm={12} md={12}>
            {selectedOrderLogs.map((log) => (
              <Collapse key={log.id} in={selectedOrderId !== null} timeout="auto" unmountOnExit>
                <Paper variant="outlined" square style={{ padding: '10px', margin: '10px' }}>
                  <Typography variant="h6" component="div">
                    Details : {log.data.exchanges[0].orderno}
                  </Typography>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={2} mr={2}>
                    <Box mr={1} mb={1}>
                      <Button variant="contained" onClick={() => handleForceExit()} color="primary" aria-label="force exit" startIcon={<ExitToAppIcon />}>
                        Force Exit
                      </Button>
                    </Box>
                    <Box mr={1} mb={1}>
                      <Button variant="contained" onClick={() => handleEdit()} color="primary" aria-label="edit" startIcon={<EditIcon />}>
                        Edit
                      </Button>
                    </Box>
                    <Box mb={1}>
                      <Button variant="contained" onClick={() => handleSave()} color="primary" aria-label="save" startIcon={<SaveIcon />}>
                        Save
                      </Button>
                    </Box>
                  </Stack>
                  {/* Display your order logs content here */}
                  {/* Example: */}
                  <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Stratergy : {log.data.setting.algo}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Broker : {log.data.setting.enablePaperTrading === "Yes" ? "Paper Trading" : log.data.exchanges[0].exchangeName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Symbol : {log.data.orderConfig.symbol}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Option Type : {log.data.orderType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Quantity : {log.data.quantity}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Buy price : {log.data.ltp}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Buy price (premium) : {log.data.premiumLTP}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {!edit &&
                        <Typography variant="body2" color="text.secondary">
                          exitOption: {log.data.isExitBasedPoint ? "Point" : "Price"}
                        </Typography>
                      }
                      {edit &&
                        <>
                          <InputLabel id="demo-simple-select-label">Exit Option</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="outlined-select-exitOption"
                            label="Exit Option"
                            helperText="Please select your exit option"
                            value={exitOption}
                            onChange={(e) => setExitOption(e.target.value)}
                          >

                            {exitOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </>

                      }
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      {!edit &&
                        <Typography variant="body2" color="text.secondary">
                          Stop Loss: {log.data.stopLoss}
                        </Typography>
                      }
                      {edit && <TextField
                        fullWidth
                        label="Stop Loss"
                        variant="outlined"
                        value={stoploss}
                        size="small"
                        margin="normal"
                        onChange={(e) => setStoploss(e.target.value)}
                      />
                      }

                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {!edit &&
                        <Typography variant="body2" color="text.secondary">
                          Take profit 1: {log.data.takeProfit1}
                        </Typography>
                      }
                      {edit &&
                        <TextField
                          fullWidth
                          label="Take profit 1"
                          variant="outlined"
                          value={takeprofit1}
                          size="small"
                          margin="normal"
                          onChange={(e) => setTakeprofit1(e.target.value)}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {!edit &&
                        <Typography variant="body2" color="text.secondary">
                          Take profit : {log.data.takeProfit}
                        </Typography>
                      }
                      {edit &&
                        <TextField
                          fullWidth
                          label="Take profit"
                          variant="outlined"
                          value={takeprofit}
                          size="small"
                          margin="normal"
                          onChange={(e) => setTakeprofit(e.target.value)}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {!edit &&
                        // <Typography variant="body2" color="text.secondary">
                        //   Enable Mid Sell : {log.data.setting.enableIntermediateSelling}
                        // </Typography>
                        <></>
                      }
                      {edit &&
                        <FormControlLabel
                          control={
                            <Switch
                              checked={enableMidSell}
                              onChange={handleChangeMidSell}
                              inputProps={{ 'aria-label': 'enable mid sell' }}
                            />
                          }
                          label={enableMidSell ? 'Enable Mid Sell Yes' : 'Enable Mid Sell No'}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {!edit &&
                        <Typography variant="body2" color="text.secondary">
                          {/* Enable Trailing Loss : {log.data.refStat.enableTrailingLoss ? "Yes" : "No"} */}
                        </Typography>
                      }
                      {edit &&
                        <FormControlLabel
                          control={
                            <Switch
                              checked={enableTL}
                              onChange={handleChangeTL}
                              inputProps={{ 'aria-label': 'enable trailing loss' }}
                            />
                          }
                          label={enableTL ? 'Enable Trailing loss Yes' : 'Enable Trailing loss No'}
                        />
                      }
                    </Grid>
                  </Grid>
                  {/* <Typography variant="body2" color="text.secondary">
                    Current P/L (refresh to see latest): {log.data.pl * log.data.quantity}
                  </Typography> */}
                  {/* Timestamp: {log.timestamp.toDate().toLocaleString()} */}

                  {/* <Box mt={2} display="flex" flexDirection="row" >
                    <Box>
                      <Button size="small" onClick={() => handleEdit()} variant="outlined" color="primary">
                        Edit
                      </Button>
                    </Box>
                    <Box ml={2}>
                      <Button size="small" onClick={() => handleSave()} variant="outlined" color="primary">
                        Save
                      </Button>
                    </Box>
                  </Box> */}
                  {/* Add more details as needed */}
                </Paper>
              </Collapse>
            ))}
          </Grid>}

          {/* Second Column: Order Timeline */}
          {objectPage && <Grid item xs={12} sm={12} md={6}>

            <Collapse key="timelineid" in={selectedOrderId !== null} timeout="auto" unmountOnExit>
              <Paper variant="outlined" square style={{ padding: '10px', margin: '10px' }}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start">
                  {selectedOrderLogs[0].data.finished !== "Yes" &&
                    <Typography variant="h5" gutterBottom>
                      Order Flow (Running)
                      &nbsp; &nbsp; &nbsp;
                    </Typography>
                  }
                  {selectedOrderLogs[0].data.finished === "Yes" &&
                    <Typography variant="h5" gutterBottom>
                      Order Flow (Completed)
                      &nbsp; &nbsp; &nbsp;
                    </Typography>
                  }
                  {selectedOrderLogs[0].data.finished !== "Yes" &&
                    <Typography variant="h5" gutterBottom style={{
                      color: selectedOrderLogs[0].data.pl > 0 ? "green" :
                        selectedOrderLogs[0].data.pl < 0 ? "red" : "green"
                    }}>
                      Profit & Loss : &nbsp; {selectedOrderLogs[0].data.pl.toFixed(2)}
                    </Typography>
                  }
                  {selectedOrderLogs[0].data.finished === "Yes" &&
                    <Typography variant="h5" gutterBottom style={{
                      color: selectedOrderLogs[0].data.pl > 0 ? "green" :
                        selectedOrderLogs[0].data.pl < 0 ? "red" : "green"
                    }}>
                      Profit & Loss : &nbsp; {selectedOrderLogs[0].data.pl.toFixed(2)}
                    </Typography>
                  }
                </Stack>
                <Stack direction="row" alignItems="left" justifyContent="flex-start" m={2}>
                  <Typography variant="body2" color="text.primary">
                    Current Premium LTP : {selectedOrderLogs[0].data.currentltp}
                  </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="left" justifyContent="flex-start" m={2}>
                  <Typography variant="body2" color="text.primary">
                    Stop Loss Hit : {selectedOrderLogs[0].data.slhit ? "Yes" : "No"}
                  </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="left" justifyContent="flex-start" m={2}>
                  <Typography variant="body2" color="text.primary">
                    Take Profit Hit : {selectedOrderLogs[0].data.tkphit? "Yes" : "No"}
                  </Typography>
                  </Stack>
                <Stack direction="row" alignItems="left" justifyContent="flex-end" m={2}>
                  <Box ml={18}>
                    <Button variant="outlined" onClick={() => onShowTriggerInfo()} startIcon={<InfoIcon />}>Trigger Info</Button>
                  </Box>
                  {/* <Box ml={2}>
                    <IconButton color="secondary" onClick={() => onRefreshOrder()} aria-label="refresh">
                      <RefreshIcon />
                    </IconButton>
                  </Box> */}
                </Stack>
                <Timeline
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  {selectedOrderLogs.map((log) => (
                    <TimelineItem key={log.data.exchanges[0].orderno}>
                      <TimelineOppositeContent color="textSecondary">
                        {log.data.exchanges[0].date && new Date(log.data.exchanges[0].date).toLocaleString()}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color={log.data.exchanges[0].orderstatus === "Executed" ? "success" : log.data.exchanges[0].orderstatus === "Rejected" ? "error" : "error"} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        {log.data.exchanges[0].orderno} | Buy : {log.data.ltp} | Status : {log.data.exchanges[0].orderstatus}
                      </TimelineContent>
                    </TimelineItem>

                  ))}
                </Timeline>

                <Timeline
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  {selectedOrderLogs[0] &&
                    selectedOrderLogs[0].data &&
                    selectedOrderLogs[0].data.exchanges[0] &&
                    selectedOrderLogs[0].data.exchanges[0].isell &&
                    selectedOrderLogs[0].data.exchanges[0].isell.map((item) => (
                      <TimelineItem key={item.orderno}>
                        <TimelineOppositeContent color="textSecondary">
                          {item.date && new Date(item.date).toLocaleString()}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color={item.orderstatus === "Executed" ? "success" : item.orderstatus === "Rejected" ? "error" : "error"} />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          {item.orderno} | Mid Sell : {item.orderltp} | Status : {item.orderstatus}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                </Timeline>

                <Timeline
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  {selectedOrderLogs[0] &&
                    selectedOrderLogs[0].data &&
                    selectedOrderLogs[0].data.exchanges[0] &&
                    selectedOrderLogs[0].data.exchanges[0].sell &&
                    selectedOrderLogs[0].data.exchanges[0].sell.map((item) => (
                      <TimelineItem key={item.orderno}>
                        <TimelineOppositeContent color="textSecondary">
                          {item.date && new Date(item.date).toLocaleString()}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color={item.orderstatus === "Executed" ? "success" : item.orderstatus === "Rejected" ? "error" : "error"} />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          {item.orderno} | Sell :  {item.orderltp} | Status : {item.orderstatus}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                </Timeline>

              </Paper>
            </Collapse>
          </Grid>}

          {/* Third Column: Order Timeline */}
          {objectPage && showTriggerInfo && <Grid item xs={12} sm={12} md={6}>
            <Collapse key="timelineid" in={selectedOrderId !== null} timeout="auto" unmountOnExit>
              <Paper variant="outlined" square style={{ padding: '10px', margin: '10px' }}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start">
                  <Typography variant="h5" gutterBottom>
                    Trigger Info &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" mr={2}>
                  {/* <IconButton color="secondary" onClick={() => onRefreshOrder()} aria-label="refresh">
                    <RefreshIcon />
                  </IconButton> */}
                </Stack>
                {selectedOrderLogs[0] &&
                  selectedOrderLogs[0].data.takeProfit1 !== 0 &&
                  <Timeline position="left"
                  >
                    {selectedOrderLogs.map((item) => (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                        >
                          Take profit1 : {item.data.takeProfit1}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color="primary">
                            <InfoIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          {/* <Typography variant="h6" component="span">
                            Info
                          </Typography> */}
                          <Typography>Mid sell</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                }
                {/* selectedOrderLogs[0].data.setting.refStatCE.enableTrailingLoss === "Yes" && */}
                {selectedOrderLogs[0] &&
                  <Timeline position="left"
                  >
                    {selectedOrderLogs.map((item) => (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                        >
                          TL1 : {item.data.tl1} | TL2 : {item.data.tl2} | TL3 : {item.data.tl3} | TL4 : {item.data.tl4}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color="primary">
                            <InfoIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          {/* <Typography variant="h6" component="span">
                            Info
                          </Typography> */}
                          <Typography>Trailing Loss</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                }

                {selectedOrderLogs[0] &&
                  selectedOrderLogs[0].data.takeProfit !== 0 &&
                  <Timeline position="left"
                  >
                    {selectedOrderLogs.map((item) => (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                        >
                          Take Profit : {item.data.takeProfit} | Stop Loss : {item.data.stopLoss}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color="primary">
                            <InfoIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          {/* <Typography variant="h6" component="span">
                            Info
                          </Typography> */}
                          <Typography>Stop Loss and Take profit</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                }
              </Paper>
            </Collapse>
          </Grid>}

        </Grid>


        {/* <Grid item xs={12} sm={6} md={6}>
            <List>
              {orders.map((order) => (
                <ListItem key={order.data.exchanges[0].orderno}>
                  <ListItemText primary={`Order ID: ${order.data.exchanges[0].orderno}`}
                    secondary={`Description: ${order.data.orderConfig.symbol} | 
                  Exchange : ${order.data.enablePaperTrading === "Yes" ? "Paper Trading" : order.data.exchanges[0].exchangeName}
                  | P/L : ${order.data.pl * order.data.quantity} | Quantity : ${order.data.quantity} | Buy Price : ${order.data.ltp}`} />
                  <Button size="small" onClick={() => handleShowLogs(order.id)} variant="outlined" color="primary">
                    Show Details
                  </Button>
                  <Button onClick={() => handleEditOrder(order.id)}  variant="outlined" color="secondary" sx={{ marginLeft: 2 }}>
                      Edit
                    </Button> 
                </ListItem>
              ))}
            </List>
          </Grid> */}

        {/* <Grid container spacing={3}>
        {orders.map((order) => (
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="P&L"
              // total={1723}
              total={order.pl}
              icon={'ant-design:sliders-filled'}
              details={order.details}
              type={order.type}
              color={order.color}
              order={order.data}
            />
          </Grid>
           ))}
        </Grid> */}
      </Container>
    </>
  );
}
